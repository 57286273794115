import React from "react";
import { FormControl, Button, Flex, Heading } from "@chakra-ui/core";
import { auth, googleProvider } from "../firebaseconfig";
import { CurrUser } from "../providers/user";

export const Login: React.FC = () => {
  const currUser = CurrUser.useContainer();

  const handleGoogleSubmit = () => {
    auth.signInWithPopup(googleProvider);
  };

  const handleSignout = () => {
    auth.signOut();
  };

  return (
    <Flex direction="column" alignItems="center">
      <Heading my={8} as="h1">
        {currUser.user ? "Logout" : "Login"}
      </Heading>
      {currUser.user && (
        <>
          <Heading as="h2">Welcome {currUser.user.displayName}</Heading>
          <Button mt={8} variantColor="teal" onClick={handleSignout}>
            Sign Out
          </Button>
        </>
      )}
      {!currUser.user && (
        <form>
          <FormControl>
            <Button mt={4} variantColor="teal" onClick={handleGoogleSubmit}>
              Sign In with Google
            </Button>
            {"  "}
            <Button mt={4} variantColor="teal">
              Sign In
            </Button>
          </FormControl>
        </form>
      )}
    </Flex>
  );
};

import React from "react";
import { Box, Heading, Flex, Text } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { CurrUser } from "../providers/user";

interface MenuProps {
  children?: React.ReactNode;
  to: string;
}

const MenuItems: React.FC<MenuProps> = (props: MenuProps) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    <Link to={props.to}>{props.children}</Link>
  </Text>
);

export const Navbar: React.FC = () => {
  const currUser = CurrUser.useContainer();

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="teal.500"
      color="white"
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg" letterSpacing={"-.1rem"}>
          Slack Demo
        </Heading>
      </Flex>

      <Box
        display={{ sm: "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
      >
        <MenuItems to="/">Docs</MenuItems>
        <MenuItems to="/">Examples</MenuItems>
        <MenuItems to="/">Blog</MenuItems>
      </Box>

      <Box display={{ sm: "none", md: "block" }} mt={{ base: 4, md: 0 }}>
        <MenuItems to="/login">{currUser.user ? "Logout" : "Login"}</MenuItems>
      </Box>
    </Flex>
  );
};

import firebase from "firebase";
import { useState } from "react";
import { createContainer } from "unstated-next";
import { auth } from "../firebaseconfig";

const useCurrUser = (initialState = null) => {
  const [user, setCurrUser] = useState<firebase.User | null>(initialState);

  auth.onAuthStateChanged((user) => {
    setCurrUser(user);
  }); // Automatically updates currUser state

  return { user };
};

export const CurrUser = createContainer(useCurrUser);

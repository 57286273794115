import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CurrUser } from "./providers/user";

import { Navbar } from "./components/Navbar";
import { Login } from "./components/Login";

const App = () => {
  return (
    <Router>
      <CurrUser.Provider>
        <Navbar />
        <Route path="/login" exact component={Login} />
      </CurrUser.Provider>
    </Router>
  );
};

export default App;
